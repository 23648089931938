body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;

  background: #000;

  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

h2,
h3 {
  font-family: 'Poppins', sans-serif;
}

.container-body {
  background: var(--color-primary-light);
}

.container-sm {
  max-width: 500px;
}

.margin-bottom-sm {
  margin-bottom: 2rem;
}

.margin-bottom-md {
  margin-bottom: 4rem;
}

.margin-bottom-lg {
  margin-bottom: 8rem;
}

.padding-y-lg {
  padding: 7rem 0;
}

@media only screen and (max-width: 62em) {
  .margin-bottom-lg {
    margin-bottom: 5rem;
  }

  .padding-y-lg {
    padding: 5rem 0;
  }
}

.main {
  background: var(--color-primary-light);
}

.header {
  padding: 30px;
  text-align: center;
}

.content {
  padding: 16px;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 2rem;
  color: #fff;
  background: #2a2a2a;
}

.image {
  width: 100%;
}

.container {
  max-width: 88.75rem;
  margin-bottom: 10rem;
}

.container-about {
  margin-top: 8rem;
}

.container--beige {
  background: #ffe9c8;
}

@media only screen and (max-width: 62em) {
  .section {
    min-height: 100svh;
  }

  .container {
    margin-bottom: 5rem;
  }

  .container-about {
    margin-top: 5rem;
  }

  .container:nth-child(2) {
    max-width: 37.5rem;
  }

  .wrapper {
    margin-bottom: 3em;
  }

  .container--beige > div {
    max-width: 600px;
    padding-left: 0;
    padding-right: 0;
  }

  .image {
    max-width: 30rem;
  }
}

.card {
  background: #495057;
  border-radius: 1rem;
  transition: all 0.3s ease;
}

.card:hover {
  transform: scale(1.03);
  background: var(--color-primary-darker);
}

.card__image {
  width: 100%;
  border-radius: 0.5rem;
}

.card__wrapper {
  padding-bottom: 0;
}

.card__icon {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 6rem;
  text-align: center;
  color: #dddddd;
}

.card__content {
  border: none;
  color: var(--color-primary-light);
  background: transparent;
}

.card__description {
  text-align: justify;
}

@media screen and (max-width: 62em) {
  .card {
    max-width: 22.5rem;
  }
}

:root {
  --color-primary-light: #f8f7f4;
  --color-primary-dark: #6c757d;
  --color-primary-darker: #343a40;
  --color-primary-darkest: #212529;
}

.main {
  overflow: hidden;
  background: var(--color-primary-light);
}

.header {
  padding: 1.9rem;
  text-align: center;
}

.content {
  padding: 1rem;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  min-height: 98svh;

  font-size: 2rem;
  color: #000;
  background: #2a2a2a;
}

.hero {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../img/home/bg-medium-compressed.jpg');
  align-items: center;
  background-position: center;
  background-size: cover;
  color: var(--color-primary-light);
}

.image {
  width: 100%;
}

.container {
  max-width: 88.75rem;
  margin-bottom: 10rem;
}

.container-about {
  margin-top: 8rem;
}

.container-values {
  background-color: var(--color-primary-light);
}

.container--darker {
  background: var(--color-primary-darkest);
  color: var(--color-primary-light);
}

.container--dark {
  background: var(--color-primary-darker);
  color: var(--color-primary-light);
}

.container--small {
  max-width: 40.625rem;
}

.container--medium {
  max-width: 53.125rem;
}

.wrapper {
  margin: 0 auto;
  max-width: 81.25rem;
}

.nav-link {
  background: var(--color-primary-light);
  color: var(--color-primary-darkest);
  font-weight: bold;
  border: 2px solid var(--color-primary-light);
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.nav-link.active,
.nav-link.active:hover {
  color: var(--color-primary-light);
  background: var(--color-primary-darkest);
  border-color: var(--color-primary-darkest);
}

.nav-link:hover {
  border-color: var(--color-primary-darkest);
}

.container-text {
  background: var(--color-primary-darker);
  color: var(--color-primary-light);
  border-radius: 1.5rem;
}

.container-stats {
  padding: 0.5rem;
  margin-bottom: 8rem;
}

@media only screen and (max-width: 62em) {
  .section {
    min-height: 100svh;
  }

  .container,
  .container-stats {
    margin-bottom: 5rem;
  }

  .container-about {
    margin-top: 5rem;
    max-width: 37.5rem;
  }

  .container-values {
    background-color: var(--color-primary-light);
  }

  .container--smaller {
    max-width: 31.25rem;
  }

  .container:nth-child(4) {
    margin-top: 3rem;
  }

  .card:hover {
    transform: scale(1.05);
  }

  .image {
    max-width: 30rem;
  }

  .container-stats {
    padding: 2rem;
  }

  .container-text {
    max-width: 32rem;
  }

  .container--medium {
    max-width: 43.75rem;
  }
}

@media only screen and (max-width: 36em) {
  .container-testimonials {
    padding-bottom: 3rem;
  }
}

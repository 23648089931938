.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  background: #000;
  z-index: 100;
}

.logo {
  width: 150px;
  height: 150px;
  fill-opacity: 0;
  stroke: var(--color-primary-light);
  stroke-width: 7px;
  stroke-dasharray: 4500;
  animation: draw 8s ease;
}

@keyframes draw {
  0% {
    stroke-dashoffset: 4500;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.logo-title {
  font-size: 2rem;
  letter-spacing: 12px;
  text-transform: uppercase;
  margin-left: 10px;
  color: var(--color-primary-light);
}

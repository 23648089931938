.footer {
  text-align: center;
  color: #f8f7f4;
  background: #343a40;
}

.footer__content {
  max-width: 43.75rem;
  padding: 3rem 0 4rem;
}

.icon {
  transition: all 0.2s;
}

.icon:hover {
  fill: #adb5bd;
}

.bar {
  background: var(--color-primary-darkest);
  font-size: 1rem;
}

@media only screen and (max-width: 62em) {
  .bar {
    font-size: 0.8rem;
  }
}

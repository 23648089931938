.main {
  background: var(--color-primary-light);
}

.header {
  padding: 30px;
  text-align: center;
}

.content {
  padding: 16px;
}

.section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 2rem;
  color: #fff;
  background: #2a2a2a;
}

.image {
  width: 100%;
}

.container {
  max-width: 88.75rem;
  margin-bottom: 8rem;
}

.container-margin-md {
  margin-bottom: 7rem;
}

.container-about {
  margin-top: 8rem;
}

.container-commitment {
  background: var(--color-primary-darkest);
  color: var(--color-primary-light);
}

.container--dark {
  background: var(--color-primary-darkest);
  color: var(--color-primary-light);
}

.container__video {
  height: 23rem;
}

.button {
  background-color: var(--color-primary-darker);
  color: var(--color-primary-light);
  padding: 1rem 2.375rem;
  transition: all 0.3s ease-out;
}

.button:hover {
  background-color: var(--color-primary-darkest);
  color: var(--color-primary-light);
  transform: translateY(-5px);
}

.margin-bottom-md {
  margin-bottom: 4rem;
}

@media only screen and (max-width: 62em) {
  .container {
    margin-bottom: 5rem;
  }

  .container-about {
    margin-top: 5rem;
    max-width: 37.5rem;
  }

  /* .container-commitment,
  .container:nth-child(2),
  .container:nth-child(3) {
    padding: 4rem 0;
  } */

  .image {
    max-width: 30rem;
  }

  .wrapper {
    margin-bottom: 3em;
  }

  /* .container--steel > div {
    max-width: 600px;
    padding-left: 0;
    padding-right: 0;
  } */
}

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');

:root {
  --primary-color: #ffe9c8;
  --primary-color-dark: #ffc46d;
}

.main {
  font-family: 'Roboto', Helvetica, sans-serif;
  background: var(--color-primary-light);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main p {
  font-size: 14px;
}

.container {
  margin-top: 100px;
  margin-bottom: 100px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-r-5 {
  margin-right: 5px;
}

.contact__text,
.contact__icon {
  color: var(--color-primary-light);
}

.contact-form {
  background: #f7f7f7;
  box-shadow: 0 23px 73px -17px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-wrap: wrap;
}

.contact-form-box__left {
  background: var(--color-primary-darker);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--color-primary-light);
  padding: 30px 30px 80px 30px;
  width: 100%;
  border-radius: 5px;
  position: relative;
}

.contact-form-box__left_footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px;
  text-align: center;
}

.contact-form-box__left_footer a {
  margin-right: 2px;
  padding: 8px;
  font-size: 1.3rem;
}

.contact__icon:hover {
  color: var(--color-primary-dark);
}

.contact-form-box__right {
  padding: 30px;
  width: 100%;
}

.btn-primary {
  background: var(--color-primary-darker);
  border-color: var(--color-primary-darker);
  color: var(--color-primary-light);
}

.btn-primary:hover {
  background: var(--color-primary-dark);
  border-color: var(--color-primary-dark);
  color: var(--color-primary-light);
}

/* Making the label break the flow */
.floating-label {
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  z-index: 5;
}

/* Hide the browser-specific focus styles */
.floating-label-field {
  margin-top: 1rem;
  color: rgba(44, 62, 80, 0.75);
  border-width: 0;
  z-index: 5;
}

.floating-label-field:focus {
  outline: 0;
  box-shadow: 0;
}

.floating-label-field::placeholder {
  color: rgba(44, 62, 80, 0.5);
}

/* Make the label and field look identical on every browser */
.floating-label,
.floating-label-field {
  font: inherit;
  line-height: 1;
  display: block;
  width: 100%;
}

.floating-label-field,
.floating-label-wrap {
  position: relative;
}

.floating-label-wrap textarea {
  resize: none;
}

/* Input Style #1 */
.floating-label-field--s1 {
  transition: border-color 0.25s ease-in-out;
  color: rgb(71 71 71);
  border-bottom: 1px solid hsl(355, 32%, 85%);
  background-color: transparent;
}

.floating-label-field--s1 + .floating-label {
  transition: transform 0.25s, opacity 0.25s ease-in-out;
  transform-origin: 0 0;
  opacity: 0.5;
}

.floating-label-field--s1:focus,
.floating-label-field--s1:not(:placeholder-shown) {
  border-color: hsl(355, 32%, 75%);
}

.floating-label-field--s1:focus + .floating-label,
.floating-label-field--s1:not(:placeholder-shown) + .floating-label {
  transform: translate(0.25em, -30%) scale(0.8);
  opacity: 0.25;
}

.floating-label-field--s1:valid:not(:placeholder-shown) {
  border-color: rgb(39 131 77 / 85%);
}

.floating-label-field--s1:invalid {
  border-color: rgb(199 9 36 / 100%);
}

@media (min-width: 993px) {
  .container {
    margin-bottom: 200px;
  }

  .contact-form-box__left {
    width: 350px;
    transform: scale(1.05);
  }

  .contact-form-box__right {
    padding: 60px;
    width: calc(100% - 350px);
  }

  .contact-form {
    flex-wrap: nowrap;
  }
}

/* Input Style #2 */
.floating-label-field--s2 {
  transition: background-color 0.25s ease-in-out;
  border: 2px solid #fff;
  border-radius: 5px;
  background-color: #fff;
}
.floating-label-field--s2 + .floating-label {
  visibility: hidden;
  transition: transform 0.25s, visibility 0.5s, opacity 0.5s ease-in-out;
  opacity: 0;
}
.floating-label-field--s2:focus + .floating-label,
.floating-label-field--s2:not(:placeholder-shown) + .floating-label {
  visibility: visible;
  transform: translateY(-80%);
  opacity: 0.5;
}
.floating-label-field--s2:valid:not(:placeholder-shown) {
  border-color: #adefc8;
  background-color: #adefc8;
}
.floating-label-field--s2:invalid {
  border-color: #ffe0e3;
  background-color: #ffe0e3;
}
.floating-label-field--s3 {
  padding: 1.5em;
  transition: border-color 0.25s ease-in-out;
  color: rgba(255, 255, 255, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  background-color: transparent;
}
.floating-label-field--s3 + .floating-label {
  position: absolute;
  top: 0.75em;
  left: 0.75em;
  display: inline-block;
  width: auto;
  margin: 0;
  padding: 0.75em;
  transition: transform 0.25s, opacity 0.25s, padding 0.25s ease-in-out;
  transform-origin: 0 0;
  color: rgba(255, 255, 255, 0.5);
}
.floating-label-field--s3:focus,
.floating-label-field--s3:not(:placeholder-shown) {
  border-color: rgba(255, 255, 255, 0.1);
}
.floating-label-field--s3:focus + .floating-label,
.floating-label-field--s3:not(:placeholder-shown) + .floating-label {
  z-index: 5;
  padding: 0.75em;
  transform: translate(0, -2em) scale(0.9);
  color: rgba(255, 255, 255, 0.25);
  background-color: #2c3e50;
}
/* Common Styles */
/* Identical inputs on all browsers */
.floating-label-field--s1:not(textarea),
.floating-label-field--s2:not(textarea),
.floating-label-field--s3:not(textarea) {
  max-height: 4em;
}
.floating-label-field--s1,
.floating-label-field--s1 + .floating-label,
.floating-label-field--s2,
.floating-label-field--s2 + .floating-label {
  padding: 1.5em;
}
.floating-label-field--s1 + .floating-label,
.floating-label-field--s2 + .floating-label {
  z-index: 5;
}
.floating-label-field--s1::placeholder,
.floating-label-field--s3::placeholder {
  color: transparent;
}

.map {
  padding-bottom: 0;
  position: relative;
  color: var(--color-primary-darkest);
}

.map iframe {
  width: 100%;
  height: 50vw;
  position: relative;
}

.text {
  max-width: 60ch;
}

@media (min-width: 992px) {
  .map {
    padding-bottom: 35%;
  }

  .map iframe {
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
}

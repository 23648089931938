.navbar {
  display: flex;
  position: fixed;

  width: 100%;
  height: 75px;
  padding: 0.5em 2em;
  background-color: transparent;
  transition: all 0.3s ease-in-out;

  z-index: 10;
}

.navbar__logo {
  width: 30px;
  cursor: pointer;
}

.navbar-scrolled {
  background-color: #f7f7f7;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.navbar-dark .nav__links li a {
  color: var(--color-primary-darker);
}

.wrapper {
  display: flex;
  justify-content: space-between;

  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
}

.container {
  display: flex;
  align-items: center;
}

.nav__links {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: -1.5em;
}

.nav__links li {
  display: inline-block;
}

.nav__links li a,
.nav__links-mobile li a {
  padding: 0.7em 1.25em;
  font-size: 1.2rem;
  color: #fff;
  text-decoration: none;
}

.nav__links-mobile {
  display: none;
}

.navbar-scrolled .navbar-link,
.navbar--not-found .navbar-link {
  color: #000;
}

.navbar-scrolled .navbar-link:hover .nav-text,
.navbar--not-found .navbar-link:hover .nav-text {
  border-bottom-color: #000;
}

.navbar-scrolled .btn-language,
.navbar--not-found .btn-language {
  border: 2px solid #000;
  color: #000;
}

.navbar-scrolled .btn-language:hover,
.navbar--not-found .btn-language:hover {
  background: #000;
  color: var(--color-primary-light);
}

.nav-text {
  transition: all 0.3s ease-in-out;
  padding-bottom: 0.5em;
  border-bottom: 1px solid transparent;
}

.navbar-link:hover .nav-text {
  border-color: var(--color-primary-light);
}

.navbar__button {
  display: none;
  background-color: transparent;
  border: none;
  z-index: 40;
}

.icon {
  font-size: 1.2rem;
  margin-right: 3px;
}

.btn-language {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1.6rem;

  background: none;
  border: 2px solid var(--color-primary-light);
  color: var(--color-primary-light);
  transition: all 0.3s ease-out;
}

.btn-language:hover {
  background: var(--color-primary-light);
  color: var(--color-primary-darkest);
}

.btn-language:focus {
  outline: none !important;
  box-shadow: none;
}

.container--mobile {
  display: none;
}

.container--active {
  display: flex;
  animation: mobileMenuAnimationActive 0.7s forwards;
}

.container--inactive {
  display: flex;
  animation: mobileMenuAnimationInactive 0.7s forwards;
}

@media (max-width: 992px) {
  .container--desktop {
    display: none;
  }

  .navbar__button {
    display: block;
  }

  .nav__links-mobile {
    display: block;
    padding: 0;
  }

  .nav__links-mobile li {
    display: block;
    margin: 2em 0;
  }

  .nav__links-mobile li a {
    font-size: 2rem;
    color: #000;
  }

  .container--mobile {
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100svh;

    background-color: #fefefe;
    z-index: 30;
  }
}

@media (max-width: 576px) {
  .nav__links-mobile li a {
    font-size: 1.5rem;
  }
}

@keyframes mobileMenuAnimationActive {
  0% {
    transform: translateY(-40rem);
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  80% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes mobileMenuAnimationInactive {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  40% {
    opacity: 0;
  }

  80% {
    display: none;
    transform: translateY(-40rem);
  }

  100% {
    display: none;
    transform: translateY(-40rem);
    opacity: 0;
  }
}

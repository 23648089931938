.container {
  max-width: 88.75rem;
  padding: 8rem 0;
  border-radius: 5rem;
  margin-bottom: 4rem;
  color: var(--color-primary-light);
  background: var(--color-primary-darkest);
  color: var(--color-primary-light);
}

.container--medium {
  max-width: 53.125rem;
}

.container > a {
  font-family: 'Maven Pro', sans-serif;
  padding: 1rem 2.375rem;
  border: none;
}

.button {
  transition: all 0.3s ease-out;
}

.button:hover {
  transform: translateY(-5px);
}

.button:focus {
  outline: none;
}

.button:active {
  transform: translateY(-2px);
}

@media only screen and (max-width: 62em) {
  .container {
    padding: 6rem 0;
    margin-bottom: 3rem;
  }

  .container--medium {
    max-width: 37.5rem;
  }
}

@media only screen and (max-width: 575px) {
  .container {
    padding: 4rem 0;
    border-radius: 0;
    margin-bottom: 0;
  }

  .container > a {
    padding: 0.5rem 1.6rem;
  }
}

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #adb5bd;
  transform-origin: bottom;
  z-index: 100;
}

.slide-in-two {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #343a40;
  transform-origin: bottom;
  z-index: 100;
}

.slide-in-three {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  transform-origin: bottom;
  z-index: 100;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  transform-origin: top;
  z-index: 100;
}

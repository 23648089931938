:root {
  --animation-time: 0.4s;
}

.bar {
  width: 30px;
  height: 3px;
  border-radius: 10px;
  background: #888;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.bar:nth-child(2) {
  margin: 5px 0;
}

.container.no-animation .bar {
  animation: none;
}

.container div:nth-child(1) {
  animation: bar1AnimationRevert var(--animation-time) ease-in-out;
}

.container div:nth-child(2) {
  transition: all var(--animation-time) ease-in-out;
  transform: scale(1);
  opacity: 1;
}

.container div:nth-child(3) {
  animation: bar3AnimationRevert var(--animation-time) ease-in-out;
}

.container.active div:nth-child(1) {
  animation: bar1Animation var(--animation-time) cubic-bezier(0.3, 1, 0.7, 1)
    forwards;
}

.container.active div:nth-child(2) {
  transform: scale(0);
  opacity: 0;
}

.container.active div:nth-child(3) {
  animation: bar3Animation var(--animation-time) cubic-bezier(0.3, 1, 0.7, 1)
    forwards;
}

@keyframes bar1Animation {
  50% {
    transform: translateY(8px);
  }
  100% {
    transform: translateY(8px) rotateZ(45deg);
  }
}

@keyframes bar3Animation {
  50% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(-8px) rotateZ(135deg);
  }
}

@keyframes bar1AnimationRevert {
  0% {
    transform: translateY(8px) rotateZ(45deg);
  }
  50% {
    transform: translateY(8px) rotateZ(0deg);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes bar3AnimationRevert {
  0% {
    transform: translateY(-8px) rotateZ(135deg);
  }
  50% {
    transform: translateY(-8px) rotateZ(0deg);
  }
  100% {
    transform: translateY(0);
  }
}
